<template>
	<swiper
		:modules="modules"
		:navigation="{ nextEl: '.event_next', prevEl: '.event_prev' }"
		:loop="bannerList.length > 1"
		:speed="1000"
		:autoplay="{ delay: 3000, disableOnInteraction: false }"
		:slides-per-view="1"
		:space-between="30"
		:breakpoints="breakpoints"
		@slideChange="onSlideChange"
		v-if="bannerList.length > 0"
		class="event_bnn_wrap"
	>
		<!-- <ul class="event_bnn_wrap"> -->
		<swiper-slide v-for="(item, index) in bannerList" :key="index" class="slide_item">
			<li class="event_bnn" @click="goContentDetailPage(item)">
				<a href="javascript:void(0);">
					<div class="event_img_wrap">
						<img alt="banner" :src="`${$image_host}/image/${item.thumb_path}`" />
					</div>
					<div class="event_txt_wrap">
						<dl>
							<dt>{{ item.evt_title }}</dt>
							<dd>
								<span>이벤트 기간</span> : {{ item.start_date.split(' ')[0] }} ~
								{{ item.end_date.split(' ')[0] }}
							</dd>
						</dl>
					</div>
				</a>
			</li>
		</swiper-slide>
	</swiper>
	<div class="no_data" v-else>
		<span class="ic"></span>
		<span class="tit">Sorry</span>
		<p>데이터가 없습니다.</p>
	</div>
	<!-- </ul> -->
</template>

<script setup>
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper-bundle.css';
import { HomeEventBannerStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import goContentDetailPage from '@/common-methods/goContentDetailPage';

const store = HomeEventBannerStore();
const { bannerList, activeIndex, totalIndex } = storeToRefs(store);

store.getHomeEventBanner();
const modules = [Autoplay, Navigation]; // Swiper 컴포넌트에 props로 들어감
// const onSwiper = (swiper) => {};   // swiper 로드 직후

const onSlideChange = (swiper) => {
	// 슬라이드 변경 시
	// console.log(swiper.realIndex);

	store.$patch({ activeIndex: swiper.realIndex });
};

// 모바일 반응형 swiper 설정
const breakpoints = ref({
	320: {
		slidesPerView: 1,
	},
	610: {
		slidesPerView: 2,
	},
});
</script>

<style scoped>
.slide_item {
	width: 49%;
	max-width: 585px;
	padding-bottom: 20px;
}

.event_img_wrap img {
	width: 100%;
}
</style>
